import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useFormik } from 'formik';
import { useHistory } from "react-router"
import Title from '../components/Title'


const Quotazione = () => {

    let history = useHistory();
    const api_url = process.env.REACT_APP_API_URL;

    const textInputStyle = "appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
    const label = (item) => (<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold text-left" for="grid-first-name">{ item }</label>)

    const [data, setData] = useState({
        loading: false,
        propertyType: '',
        floors: '',
        conditions: '',
        heatingTypologies: '',
        energyClasses: '',
    })
    const [showForm, setShowForm] = useState({
        showStep1: true,
        showStep2: false,
        showStep3: false,
    })
    const [values, setValues] = useState({
        customerInfo: {
            name: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            acconsent: true
        },
        propertyInfo: {
            location: {
                address: "",
                civicNumber: "",
                city: "",
                state: "",
                zip: ""
            },
            buildingTypology: "",
            size: "",
            floor: "",
            rooms: "",
            bathrooms: "",
            condition: "",
            heatingTypology: "",
            constructionYear: "",
            elevator: true,
            balcony: true,
            garden: true,
            garage: true,
            energyClass: ""
        }
    })


    const fetchData = async () => {
        try {
            const response = await axios.get(api_url + '/api/v1/data')
            setData({
                loading: true,
                propertyType: response.data.propertyType,
                floors: response.data.floors,
                conditions: response.data.conditions,
                heatingTypologies: response.data.heatingTypologies,
                energyClasses: response.data.energyClasses,
            })
        } catch (error) {
            console.log(error)
        }
    }
    const showFormStep1 = () => {
        setShowForm({
            showStep1: true,
            showStep2: false,
            showStep3: false,
        })
    }

    const showFormStep2 = () => {
        if (formik.values.customerInfo.acconsent != true) {
             alert('Devi dare il consenso al trattamento dei dati personali per proseguire')
             return
         }
         if (formik.values.customerInfo.name == "") {
             alert('Devi inserire il nome e cognome')
             return
         }
         if (formik.values.customerInfo.email == "") {
             alert('Devi inserire l\'email')
             return
         }
        setShowForm({
            showStep1: false,
            showStep2: true,
            showStep3: false,
        })
    }

    const showFormStep3 = () => {
        if (formik.values.propertyInfo.location.address == "") {
            alert('Devi inserire l\'indirizzo dell\'immobile')
            return
        }
        if (formik.values.propertyInfo.location.civicNumber == "") {
            alert('Devi inserire il numero civico dell\'immobile')
            return
        }
        if (formik.values.propertyInfo.location.zip == "") {
            alert('Devi inserire il CAP dell\'immobile')
            return
        }
        if (formik.values.propertyInfo.location.city == "") {
            alert('Devi inserire la città dell\'immobile')
            return
        }
        if (formik.values.propertyInfo.location.state == "") {
            alert('Devi inserire la provincia dell\'immobile')
            return
        }
        setShowForm({
            showStep1: false,
            showStep2: false,
            showStep3: true,
        })
    }

    const sendData = async (values) => {
        try {
            const response = await axios.post(api_url + '/api/v1/estimates', values)
            console.log(response)
            history.push("/grazie")
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = event => {
        setValues(prevValues => ({
          ...prevValues,
          [event.target.name]: event.target.value
        }));
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: values,
        onSubmit: values => {  
                console.log(values)    
                sendData(values)    
        },
    });

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='container mx-auto py-32 flex flex-col gap-4 px-8 md:px-0'>
            <Title item='Valuta il tuo immmobile in 3 semplici passaggi' />
            <div className='text-left text-base pb-4'>
            Compila i campi sottostanti per ricevere la valutazione del tuo immobile
            </div>
            <div className='relative w-full flex flex-row items-center'>
            <div className='absolute h-1 redBg mt-12 left-0 w-full'></div>
            <div className='absolute top-0 left-0 w-full flex flex-row items-center'>
                <div className='w-1/3 flex flex-col items-center'>
                    <button 
                    className={"mx-auto h-12 w-12 rounded-full text-2xl text-center text-white flex items-center " + (showForm.showStep1 == true ? "redBg" : "bg-black")}
                    onClick={showFormStep1}>
                        <span className='text-center w-full'>
                            1
                        </span>
                    </button>
                </div>
                <div className='w-1/3 flex flex-col items-center'>
                    <button 
                    className={"mx-auto h-12 w-12 rounded-full text-2xl text-center text-white flex items-center " + (showForm.showStep2 == true ? "redBg" : "bg-black")}
                    onClick={showFormStep2}>
                        <span className='text-center w-full'>
                            2
                        </span>
                    </button>
                </div>
                <div className='w-1/3 flex flex-col items-center'>
                    <button 
                    className={"mx-auto h-12 w-12 rounded-full text-2xl text-center text-white flex items-center " + (showForm.showStep3 == true ? "redBg" : "bg-black")}
                    onClick={showFormStep3}>
                        <span className='text-center w-full'>
                            3
                        </span>
                    </button>
                </div>
            </div>
            </div>

            <form onSubmit={formik.handleSubmit} className='w-full pt-24'>

                {showForm.showStep1 ? (
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4">
                <div className="col-span-4 text-xl text-center redText">
                    Dati Richiedente
                </div>
                <div className="col-span-4 ">
                        { label('Nome e Cognome *') }
                        <input class={textInputStyle} 
                                id="grid-first-name" 
                                type="text"
                                name="customerInfo.name"
                                value={formik.values.customerInfo.name}
                                onChange={formik.handleChange}
                                placeholder=""
                                required />
                    </div>
                    <div className="col-span-4 md:col-span-2">
                        { label('Email *') }
                        <input class={textInputStyle} 
                                id="grid-first-name" 
                                type="text" 
                                name="customerInfo.email"
                                value={formik.values.customerInfo.email}
                                onChange={formik.handleChange}
                                placeholder=""
                                required />
                    </div>
                    <div className="col-span-4 md:col-span-2">
                        { label('telefono') }
                        <input class={textInputStyle} 
                                id="grid-first-name" 
                                type="text" 
                                name="customerInfo.phone"
                                value={formik.values.customerInfo.phone}
                                onChange={formik.handleChange}
                                placeholder="" />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                        { label('indirizzo') }
                        <input class={textInputStyle} 
                                id="grid-first-name" 
                                type="text" 
                                name="customerInfo.address"
                                value={formik.values.customerInfo.address}
                                onChange={formik.handleChange}
                                placeholder="" />
                    </div>
                    <div className="col-span-4 md:col-span-1">
                        { label('Città') }
                        <input class={textInputStyle} 
                                id="grid-first-name" 
                                type="text" 
                                name="customerInfo.city"
                                value={formik.values.customerInfo.city}
                                onChange={formik.handleChange}
                                placeholder="" />
                    </div>
                    <div className='col-span-4'>
                        <input type="checkbox" name="customerInfo.acconsent" value={formik.values.customerInfo.acconsent} onChange={formik.handleChange} /> 
                        Consenso al trattamento dei dati personali per le <a href='/privacy' className='underline'>
                         finalità qui descritte    
                        </a>
                    </div>
                    <div className='col-span-4'>
                        <button type="button" className="bg-black hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                        onClick={showFormStep2}>
                            PROSEGUI
                        </button>
                    </div>
                </div>
                ) : ('')}

                {showForm.showStep2 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4">
                    <div className="col-span-4 text-xl text-center redText">
                        Indirizzo Immobile
                    </div>
                  <div className="col-span-4 md:col-span-3 ">
                          { label('Indirizzo *') }
                          <input class={textInputStyle} 
                                  id="grid-first-name" 
                                  type="text"
                                  name="propertyInfo.location.address"
                                  value={formik.values.propertyInfo.location.address}
                                  onChange={formik.handleChange}
                                  placeholder=""
                                  required />
                      </div>
                      <div className="col-span-4 md:col-span-1 ">
                          { label('Nr.*') }
                          <input class={textInputStyle} 
                                  id="grid-first-name" 
                                  type="text"
                                  name="propertyInfo.location.civicNumber"
                                  value={formik.values.propertyInfo.location.civicNumber}
                                  onChange={formik.handleChange}
                                  placeholder=""
                                  required />
                      </div>
                      <div className="col-span-4 md:col-span-1 ">
                          { label('Cap *') }
                          <input class={textInputStyle} 
                                  id="grid-first-name" 
                                  type="text"
                                  name="propertyInfo.location.zip"
                                  value={formik.values.propertyInfo.location.zip}
                                  onChange={formik.handleChange}
                                  placeholder=""
                                  required />
                      </div>
                      <div className="col-span-4 md:col-span-2 ">
                          { label('Città *') }
                          <input class={textInputStyle} 
                                  id="grid-first-name" 
                                  type="text"
                                  name="propertyInfo.location.city"
                                  value={formik.values.propertyInfo.location.city}
                                  onChange={formik.handleChange}
                                  placeholder=""
                                  required />
                      </div>
                      <div className="col-span-4 md:col-span-1">
                          { label('Prov. *') }
                          <input class={textInputStyle} 
                                  id="grid-first-name" 
                                  type="text"
                                  name="propertyInfo.location.state"
                                  value={formik.values.propertyInfo.location.state}
                                  onChange={formik.handleChange}
                                  placeholder=""
                                  required />
                      </div>
                      <div className='col-span-4'>
                            <button type="button" className="bg-black hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                                onClick={showFormStep3}>
                            PROSEGUI
                            </button>
                        </div>
                </div>
                ) : ('')}

                {showForm.showStep3 ? (
               <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4">
                    <div className="col-span-4 text-xl text-center redText">
                        Dati Immobile
                    </div>
                    <div className="col-span-4 md:col-span-2 ">
                            { label('Tipologia *') }
                                <select class={textInputStyle} id="grid-first-name" 
                                    type="text"
                                    name="propertyInfo.buildingTypology"
                                    value={formik.values.propertyInfo.buildingTypology}
                                    onChange={formik.handleChange}
                                    required>
                                    <option value="">Seleziona</option>
                                    {data.propertyType.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}

                                </select>
                    </div>
                    <div className="col-span-4 md:col-span-2 ">
                         { label('Dimensione (Mq.) *') }
                            <input class={textInputStyle} 
                                type='number'
                                name="propertyInfo.size"
                                value={formik.values.propertyInfo.size}
                                onChange={formik.handleChange}
                                placeholder=""
                                required />
                    </div>
                    <div className="col-span-4 md:col-span-2 ">
                        { label('Piano') }
                        <select class={textInputStyle} id="grid-first-name" name='propertyInfo.floor' value={formik.values.propertyInfo.floor} onChange={formik.handleChange}>
                            <option value="">Seleziona</option>
                            {data.floors.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-4 md:col-span-1 ">
                         { label('Nr. Stanze *') }
                            <input class={textInputStyle} 
                                type='number'
                                name="propertyInfo.rooms"
                                value={formik.values.propertyInfo.rooms}
                                onChange={formik.handleChange}
                                placeholder=""
                                required />
                    </div>
                    <div className="col-span-4 md:col-span-1 ">
                         { label('Nr. Bagni *') }
                            <input class={textInputStyle} 
                                type='number'
                                name="propertyInfo.bathrooms"
                                value={formik.values.propertyInfo.bathrooms}
                                onChange={formik.handleChange}
                                placeholder=""
                                required />
                    </div>
                    <div className="col-span-4 md:col-span-2 ">
                        { label('Condizioni *') }
                        <select class={textInputStyle} id="grid-first-name" name='propertyInfo.condition' value={formik.values.propertyInfo.condition} onChange={formik.handleChange} required>
                            <option value="">Seleziona</option>
                            {data.conditions.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-4 md:col-span-2">
                        { label('Tipologia Riscaldamento *') }
                        <select class={textInputStyle} id="grid-first-name" name='propertyInfo.heatingTypology' value={formik.values.propertyInfo.heatingTypology} onChange={formik.handleChange} required>
                            <option value="">Seleziona</option>
                            {data.heatingTypologies.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-4 md:col-span-2 ">
                        { label('Classe Energetica *') }
                        <select class={textInputStyle} id="grid-first-name" name='propertyInfo.energyClass' value={formik.values.propertyInfo.energyClass} onChange={formik.handleChange} required>
                            <option value="">Seleziona</option>
                            {data.energyClasses.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-4 md:col-span-2">
                        { label('Anno Costruzione *') }
                        <input class={textInputStyle} name='propertyInfo.constructionYear' value={formik.values.propertyInfo.constructionYear} onChange={formik.handleChange} required />
                    </div>
                    <div className="col-span-4 md:col-span-1 flex gap-2 items-center">
                        <input type="checkbox" name="propertyInfo.elevator" value={formik.values.propertyInfo.elevator} onChange={formik.handleChange} />
                        { label('Ascensore') }
                    </div>
                    <div className="col-span-4 md:col-span-1 flex gap-2 items-center">
                        <input type="checkbox" name="propertyInfo.balcony" value={formik.values.propertyInfo.balcony} onChange={formik.handleChange} />
                        { label('Balcone') }
                    </div>
                    <div className="col-span-4 md:col-span-1 flex gap-2 items-center">
                        <input type="checkbox" name="propertyInfo.garden" value={formik.values.propertyInfo.garden} onChange={formik.handleChange} />
                        { label('Giardino') }
                    </div>
                    <div className="col-span-4 md:col-span-1 flex gap-2 items-center">
                        <input type="checkbox" name="propertyInfo.garage" value={formik.values.propertyInfo.garage} onChange={formik.handleChange} />
                        { label('Garage') }
                    </div>
                    <div className='col-span-4'>
                            <button type="submit" className="bg-black hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                                onClick={showFormStep3}>
                            Ottieni Valutazione
                            </button>
                        </div>

                </div>
                ) : ('')}

            </form>
        </div>
    )
}


export default Quotazione;