import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useHistory } from "react-router"

import Title from '../components/Title'
import CardProperty from '../components/CardProperty'
import Categorie from '../components/Categorie'

import residenzialeVendita from '../img/residenziale-vendita.jpg'
import residenzialeAffitto from '../img/residenziale-affitto.jpg'
import commercialeVendita from '../img/commerciale-vendita.jpg'
import commercialeAffitto from '../img/commerciale-affitto.jpg'
import NewsCard from '../components/NewsCard'
const ChiSiamo = () => {

    let history = useHistory();
    const api_url = process.env.REACT_APP_API_URL;

    const [properties, setProperties] = useState({
        items: {},
        isLoading: false
    });

    const [news, setNews] = useState({
        items: {},
        isLoading: false
    })

    const categorie = [
        {name: 'Residenziale Vendita', src: residenzialeVendita, cat: 'R', type: 'V'},
        {name: 'Residenziale Affitto', src: residenzialeAffitto, cat: 'R', type: 'A'},
        {name: 'Commerciale Vendita', src: commercialeVendita, cat: 'C', type: 'V'},
        {name: 'Commerciale Affitto', src: commercialeAffitto, cat: 'C', type: 'A'}
    ]

    const getMainProperties = () => {
        axios.get(api_url + '/api/properties')
            .then(response => {
                console.log(response);
                setProperties(
                    {
                        items: response.data.properties,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getNews = () => {
        axios.get(api_url + '/api/posts')
            .then(response => {
                console.log(response.data);
                setNews(
                    {
                        items: response.data.posts,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    const showProperty = (id) => {
        history.push("/properties/" + id);
    }
    

    let newsRender = ''
    if (news.isLoading) {
       newsRender =( news.items.map(item => (<NewsCard item={item} />)) )
    }

    useEffect(() => {
        getMainProperties();
        getNews()
    }, []);

    return (

        <div className="container mx-auto mb-32 px-4 md:px-0">
            <Title item="Chi Siamo" />
            <p className="text-base greyText text-justify ubuntu">
                La nostra agenzia è presente da 15 anni sul mercato immobiliare della provincia di Mantova
                e nasce per soddisfare le esigenze abitative di voi clienti , con la massima attenzione , professionalità ,
                trasparenza ed etica . 
                <br/>
                <br/>
                Crediamo che l’acquisto , la vendita e la locazione di un immobile rappresentino momenti fondamentali
                nella vita di ognuno di noi e che per tanto vadano gestiti nei migliori dei modi , in serenità e tranquillità tramite professionisti
                qualificati e abilitati .
                <br/>
                <br/>
                Tramite consulenti creditizi , offriamo l’opportunità di consulenze gratuite per chi vuole accedere ad un
                mutuo per acquisto casa e per pratiche edilizie /catastali forniamo la consulenza con geometri/architetti di esperienza .
                <br/>
                <br/>
                Il nostro aggiornamento continuo sul mercato immobiliare con le sue sfaccettature , viene garantito da
                corsi di formazione tramite l’ associazione di categoria agenti immobiliari FIAIP .
            </p>
            <p className="text-base text-center text-4xl ubuntu redText">
            “ NESSUN POSTO E’ BELLO COME CASA MIA “
            </p>
        
            <div className="container mx-auto pt-10 pb-10">
                <Title item="Immobili in Primo Piano"></Title>

            {properties.isLoading ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                    {properties.items.map(item => (
                        <CardProperty
                            item={item.attrs}
                            key={item._id}
                            showProperty={showProperty}
                        />
                    ))}
                </div>
            ) : ('')}
            </div>

            <div className="container mx-auto pt-10 pb-10">
                    <Title item="Tutti gli Immobili"></Title>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-2 gap-4">
                    {categorie.map((item, index) => (
                        <Categorie 
                            key={index}
                            title={item.name}
                            src={item.src}
                            cat={item.cat}
                            type={item.type} />
                    ))}
                    </div>
            </div>

            <div className="container mx-auto pb-10">
                <Title item="News" />
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                    { newsRender }
                </div>
            </div>

        </div>


    )

}

export default ChiSiamo;