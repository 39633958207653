import React from 'react'

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const SwiperSlider = ( {items} ) => {

  const api_url = process.env.REACT_APP_API_URL;

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      {items.map(item => (
      <SwiperSlide key={item._id}>
        <a href={item.link  != "null" ? item.link : '/'} rel="noreferrer">
          <img 
            src={api_url + '/' + item.slider} 
            alt={item.title} 
            className="w-full" 
        />
        </a>
      </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperSlider;

