const getListPic = (item) => {
    let list = [];

    for (const [key, value] of Object.entries(item)) {
        if (key.startsWith("url")) {
            console.log(`${key}: ${value}`);
            list.push(value[0]);
        }
      }
      
    return list;
}

export default getListPic;