import React from 'react';

import TopHeader from './components/TopHeader'
import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import ChiSiamo from './pages/ChiSiamo'
import PageDetail from './pages/PageDetail'
import Properties from './pages/Properties'
import Property from './pages/Property'
import News from './pages/News'
import NewsDetail from './pages/NewsDetail'
import Contatti from './pages/Contatti'
import Cookies from './pages/Cookie'
import Terms from './pages/Terms'
import Quotazione from './pages/Quotazione';
import Grazie from './pages/Grazie';
import CookiePolicy from './pages/CookiePolicy'
import '@fortawesome/fontawesome-free/css/all.css';

import './App.css';
import './styles/main.css';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  //Link
} from "react-router-dom";

/* import ReactGA from 'react-ga';
ReactGA.initialize('UA-173924212-1');
ReactGA.pageview(window.location.pathname + window.location.search); */

function App() {
  
  return (
    <div className="App">
      <TopHeader></TopHeader>
      <Header></Header>
      <Router>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/chi-siamo" component={ChiSiamo} />
            <Route path="/pages" component={PageDetail} />
            <Route exact path="/properties" component={Properties} />
            <Route exact path="/properties/:id" component={Property} />
            <Route exact path="/properties/:cat/:type" component={Properties} />
            <Route exact path="/news" component={News} />
            <Route exact path="/news/:id" component={NewsDetail} />
            <Route exact path="/contatti" component={Contatti} />
            <Route exact path="/privacy" component={Cookies} />
            <Route exact path="/termini-e-condizioni" component={Terms} />
            <Route exact path="/quotazione" component={Quotazione} />
            <Route exact path="/grazie" component={Grazie} />
        </Switch>
      </Router>
      <Footer></Footer>


    </div>
  );
}

export default App;
