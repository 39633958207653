const getCondizioni = (item) => {

    switch(item) {
        case "ND":
            return "Non definite"
        case "NC":
            return "Nuova costruzione"
        case "RI":
            return "Ristrutturato"
        case "DR":
            return "Da ristrutturare"
        case "AB":
            return "Abitabile"
        case "OT":
            return "Ottime"
        case "SM":
            return "Seminuovo"
        default:
            return ""
    }

}

export default getCondizioni;