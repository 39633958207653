import React from 'react'


const pageDetail = () => {

    return (

        <div>Chi Siamo id</div>
        
    )

}



export default pageDetail;