import React from 'react'
import Title from '../components/Title'
import Mappa from '../components/MappaProperty'
import Form from '../components/FormContatti'

const Contatti = () => {


    return (

        <div className="container mx-auto px-4 md:px-0">
            <div className="grid grid-cols-1 sm:grid-cols-6 md:grid-colds-6 lg:grid-cols-6 gap-4 mt-8 mb-32">
                <div className="col-span-4">
                    <Form/>
                </div>
                <div className="col-span-2">
                    <Title item="Contatti" />
                    <p className="ubuntu text-left greyText">
                        <strong>
                        STUDIO VIRGILIO di Valenza Jolena <br/>
                        </strong>
                        P.I. 02248030203- C.F. VLNJLN75D44E897C
                    </p>
                    <p className="ubuntu text-left greyText mt-4">
                        Via F.lli Cervi, 46 <br/>
                        46034 Borgo Virgilio (MN) <br/>
                        Tel. 0376 1697786
                    </p>
                    <p className="ubuntu text-left greyText mt-4">
                        E-mail: info@immobiliarestudiovirgilio.it <br/>
                        Pec: studiovirgilio@arubapec.it <br/>
                    </p>
                    <p className="ubuntu text-left greyText mt-4">
                        <strong>
                            Jolena Valenza
                        </strong> <br/>
                        Tel. 0376 1697786 <br/>
                        j.valenza@immobiliarestudiovirgilio.it

                    </p>
                    <p className="ubuntu text-left greyText mt-4">
                        <strong>
                            Geom. Sebastiano Bosi
                        </strong> <br/>
                        Tel. 340 1819887 <br/>
                        s.bosi@immobiliarestudiovirgilio.it
                    </p>
                </div>
            </div>
            <div className="w-full">
                <Mappa latitude="45.1173103" longitude="10.7911372" />
            </div>
        </div>
    )
}


export default Contatti;