import React, {useState, useEffect}  from 'react'
import axios from 'axios'

import Title from '../components/Title'
import NewsCard from '../components/NewsCard'


const News = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [news, setNews] = useState({
        items: {},
        isLoading: false
    })

    const getNews = () => {
        axios.get(api_url + '/api/posts')
            .then(response => {
                console.log(response.data);
                setNews(
                    {
                        items: response.data.posts,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    let newsRender = ''
    if (news.isLoading) {
       newsRender =( news.items.map(item => (<NewsCard item={item} />)) )
    }

    useEffect(() => {
        getNews();
    }, []);

    return (

        <div className="container mx-auto pb-10 px-4 md:px-0">
            <Title item="News" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                { newsRender }
            </div>
        </div>

    )
}


export default News;