import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { useFormik } from 'formik';
import Contatti from '../pages/Contatti'
import HCaptcha from '@hcaptcha/react-hcaptcha';

const ContattiForm = ({subject}) => {

    const api_url = process.env.REACT_APP_API_URL;

    const textInputStyle = "appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
    const label = (item) => (<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-first-name">{ item }</label>)

    const [values, setValues] = useState({
        nome: '',
        cognome: '',
        indirizzo: '',
        civico: '',
        cap: '',
        citta: '',
        prov: '',
        email: '',
        tel: '',
        oggetto: subject,
        descrizione: ''
    })

    const handleChange = event => {
        setValues(prevValues => ({
          ...prevValues,
          [event.target.name]: event.target.value
        }));
    }

    const sendData = (values) => {
        axios.post(api_url + '/api/mailer', values)
                .then(response => {
                    console.log(response);
                    alert("email inviata con successo")
                })
                .catch(error => {
                    console.log(error);
                    alert("email non inviata");
                });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: values,
        onSubmit: values => {  
                sendData(values)        
        },
    });


    return (
        <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4">

            <div className="col-span-2 ">
                { label('Nome') }
                <input class={textInputStyle} 
                        id="grid-first-name" 
                        type="text"
                        name="nome"
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>
            <div className="col-span-2">
                { label('Cognome') }
                <input class={textInputStyle} 
                        id="grid-first-name" 
                        type="text" 
                        name="cognome"
                        value={formik.values.cognome}
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>

            <div className="col-span-3">
                { label('Indirizzo') }
                <input class={textInputStyle} 
                        id="grid-first-name" 
                        type="text"
                        name="indirizzo"
                        value={formik.values.indirizzo}
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>
            <div className="col-span-1">
                { label('Civico') }
                <input class={textInputStyle} 
                        id="grid-first-name" 
                        type="text"
                        name="civico"
                        value={formik.values.civico} 
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>

            <div className="col-span-1">
                { label('Cap') }
                <input class={textInputStyle} 
                        id="grid-first-name" 
                        type="text"
                        name="cap"
                        value={formik.values.cap}
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>
            <div className="col-span-2">
                { label('Citta') }
                <input class={textInputStyle} 
                        id="grid-first-name" 
                        type="text" 
                        name="citta"
                        value={formik.values.citta}
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>
            <div className="col-span-1">
                { label('Prov') }
                <input class={textInputStyle} 
                        id="grid-first-name" 
                        type="text" 
                        name="prov"
                        value={formik.values.prov}
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>

            <div className="col-span-2">
                { label('email') }
                <input class={textInputStyle} 
                        id="grid-first-name" 
                        type="text" 
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>
            <div className="col-span-2">
                { label('Tel') }
                <input className={textInputStyle} 
                        id="grid-first-name" 
                        type="text" 
                        name="tel"
                        value={formik.values.tel}
                        onChange={formik.handleChange}
                        placeholder="" />
            </div>

            <div className="col-span-4">
                { label('Oggetto') }
                <input className={textInputStyle} 
                        id="grid-first-name" 
                        type="text"
                        name="oggetto"
                        value={formik.values.oggetto}
                        onChange={formik.handleChange} 
                        placeholder="" />
            </div>

            <div className="col-span-4">
                <textarea className={textInputStyle}
                    name="descrizione"
                    type="text"
                    value={formik.values.descrizione}
                    onChange={formik.handleChange} ></textarea>
            </div>

            <HCaptcha
                sitekey="cca4fd64-27f0-4fc3-8b7b-8bc823716628"
                />

            <div className="col-span-4">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                        type="submit">INVIA</button>
            </div>

        </div>
    </form>

    )

}


export default ContattiForm;