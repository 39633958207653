import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useHistory } from "react-router"

import SwiperSlider from '../components/SwiperSlider'
import Title from '../components/Title'
import CardProperty from '../components/CardProperty'
import Categorie from '../components/Categorie'
import Map from '../components/Mappa'

import residenzialeVendita from '../img/residenziale-vendita.jpg'
import residenzialeAffitto from '../img/residenziale-affitto.jpg'
import commercialeVendita from '../img/commerciale-vendita.jpg'
import commercialeAffitto from '../img/commerciale-affitto.jpg'
import NewsCard from '../components/NewsCard'
import ValuePropertyBanner from '../components/ValuePropertyBanner'
const Home = () => {

    let history = useHistory()

    const api_url = process.env.REACT_APP_API_URL;

    const [sliders, setSliders] = useState({
        items: {},
        isLoading: false
    });

    const [news, setNews] = useState({
        items: {},
        isLoading: false
    })

    const [properties, setProperties] = useState({
        items: {},
        isLoading: false
    });

    const [xmlProperties, setXmlProperties] = useState({
        items: {},
        isLoading: false
    });

    const categorie = [
        {name: 'Residenziale Vendita', src: residenzialeVendita, cat: 'R', type: 'V'},
        {name: 'Residenziale Affitto', src: residenzialeAffitto, cat: 'R', type: 'A'},
        {name: 'Commerciale Vendita', src: commercialeVendita, cat: 'C', type: 'V'},
        {name: 'Commerciale Affitto', src: commercialeAffitto, cat: 'C', type: 'A'}
    ]

    const getSliders = () => {
        axios.get(api_url + '/api/sliders')
            .then(response => {
                console.log(response.data);
                setSliders(
                    {
                        items: response.data.sliders,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getNews = () => {
        axios.get(api_url + '/api/posts')
            .then(response => {
                console.log(response.data);
                setNews(
                    {
                        items: response.data.posts,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getMainProperties = () => {
        axios.get(api_url + '/api/properties')
            .then(response => {
                console.log(response);
                setProperties(
                    {
                        items: response.data.properties,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getAllProperties = () => {
        axios.get(api_url + '/api/xml')
            .then(response => {
                console.log(response);
                setXmlProperties(
                    {
                        items: response.data,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    const showProperty = (id) => {
        history.push("/properties/" + id);
    }

    useEffect(() => {
        getSliders();
        getMainProperties();
        getAllProperties();
        getNews();
    }, []);

    let mappa = ''
    if (xmlProperties.isLoading) {
        mappa = (
                    <Map
                        items={xmlProperties.items}>
                    </Map>
                )
    }

    let newsRender = ''
    if (news.isLoading) {
       newsRender =( news.items.map(item => (<NewsCard item={item} />)) )
    }

    
    return (

            <div>
                {sliders.isLoading ? (
                <SwiperSlider
                    items={sliders.items}></SwiperSlider>
                ) : ('')}

                <div className="container mx-auto pt-10 pb-10 px-4 md:px-0">
                    <Title item="Immobili in Primo Piano"></Title>

                    {properties.isLoading ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                        {properties.items.map(item => (
                            <CardProperty
                                item={item.attrs}
                                key={item._id}
                                showProperty={showProperty}
                            />
                        ))}
                    </div>
                    ) : ('')}

                </div>

                <div className="container mx-auto pb-10 px-4 md:px-0">
                    <Title item="Valutazione Immobiliare"></Title>
                    <ValuePropertyBanner/>
                </div>

                <div className="container mx-auto pt-10 pb-10 px-4 md:px-0">
                    <Title item="Tutti gli Immobili"></Title>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-2 gap-4">
                    {categorie.map((item, index) => (
                        <Categorie 
                            key={index}
                            title={item.name}
                            src={item.src}
                            cat={item.cat}
                            type={item.type} />
                    ))}
                    </div>
                </div>

                <div className="container mx-auto pb-10 px-4 md:px-0">
                    <Title item="News" />
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                        { newsRender }
                    </div>
                </div>

                <div className="mx-auto">
                    {mappa}
                </div>

            </div>
            
    )

}

export default Home;