import React from 'react'
import iconTrueFalse from '../utils/iconTrueFalse'
import getTipoContratto from '../utils/getTipoContratto'
import getTipologiaImmobile from '../utils/getTipologiaImmobile'
import getCondizioni from '../utils/getCondizioni'
import getTipologiaRiscaldamento from '../utils/getTipologiaRiscaldamento'

const Table = ({item}) => {

    const listItems = [
        {name: 'Codice', value: item.$.id},
        {name: 'Tipo Contratto', value: getTipoContratto(item.contratto[0])},
        {name: 'Tipologia Immobile', value: getTipologiaImmobile(parseInt(item.cod_tipologia[0]))},
        {name: 'Condizioni', value: getCondizioni(item.cod_condizioni[0])},
        {name: 'MQ.', value: item.mq},
        {name: 'Piano', value: item.piano},
        {name: 'Camere', value: item.camere},
        {name: 'Bagni', value: item.bagni},
        {name: 'Vani', value: item.vani},
        {name: 'Riscaldamento', value: item.riscaldamento},
        {name: 'Tipologia Riscaldamento', value: getTipologiaRiscaldamento(item.cod_riscaldamento[0])},
        {name: 'Giardino', value: iconTrueFalse(item['giardino'][0])},
        {name: 'Mq. Giardino', value:item.giardino_mq },
        {name: 'Mq. Terreno', value:item.terreno_mq },
        {name: 'Posto Auto', value: iconTrueFalse(item['posto_auto'])},
        {name: 'Mq. Posto Auto', value: item.postoauto_mq},
        {name: 'Balcone', value: iconTrueFalse(item['balcone'][0])},
        {name: 'Terrazza', value: iconTrueFalse(item['terrazza'][0])},
        {name: 'Mq. Terrazza', value: item.terrazza_mq},
        {name: 'Mansarda', value: iconTrueFalse(item['mansarda'][0])},
        {name: 'Mq. Mansarda', value: item.mansarda_mq},
        {name: 'Cantina', value: iconTrueFalse(item['cantina'][0])},
        {name: 'Mq. Cantina', value: item.cantina_mq},
        {name: 'Piscina', value: iconTrueFalse(item['piscina'][0])},
        {name: 'Mq. Piscina', value: item.piscina_mq},
        {name: 'Arredato', value: iconTrueFalse(item['arredato'][0])},
        {name: 'Ascensore', value: iconTrueFalse(item['ascensore'][0])},
        {name: 'Spese Condominiali', value: iconTrueFalse(item['spese_condominiali'][0])},
        {name: 'Vista Mare', value: iconTrueFalse(item['vista_mare'][0])},
    ]

    return (

       <div>
           <table class="table-fixed w-full">
                <thead>
                    <tr>
                    <th class="w-2/3 px-4 py-1"></th>
                    <th class="w-1/3 px-4 py-1"></th>
                    </tr>
                </thead>
                <tbody>
                    { listItems.map((lItem, index) => (
                    <tr className="">
                        <td class="border px-4 py-1 text-left text-sm">{ lItem.name }</td>
                        <td class="border px-4 py-1 text-right text-sm">{ lItem.value }</td>
                    </tr>
                    ))}
                </tbody>
            </table>
       </div> 
    )
}


export default Table;