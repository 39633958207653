
const getTipologiaRiscaldamento = (item) => {

    switch(item) {

        case "ND":
            return "Non definite"
        case "CN":
            return "Centralizzato"
        case "AU":
            return "Autonomo"
        case "IN":
            return "Inesistente"
        default:
            return ""

    }

}

export default getTipologiaRiscaldamento;