import React from 'react'
import Social from './Social'
const topHeader = () => {

    return (

        <div className="relatve top-0 left-0 redBg pt-1 pb-1">
            <div className="container mx-auto">
            <div class="grid grid-cols-2 gap-0">
                <div>
                    <p className="text-white text-sm text-left pt-1 pl-4">
                        <a href="tel:+3903761697786">
                            Tel. 0376 1697786 
                        </a>
                    </p>
                </div>
                <div className="">
                    <Social />
                </div>

            </div>

            </div>
        </div>

    )
}

export default topHeader;