import React, {useEffect} from 'react'

import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet"
import L from 'leaflet';

// eslint-disable-next-line  
delete L.Icon.Default.prototype._getIconUrl  
// eslint-disable-next-line  
L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
})


const Mappa = ({items}) => {
    let map = '';

    const initMap = () => {
        map = L.map('map', {
            maxBounds: L.latLngBounds(L.latLng(-200, -250), L.latLng(200, 250)),
            zoomControl: false,
        }).setView([15, 25], 4);

        L.tileLayer(
            'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
            { maxZoom: 15, minZoom: 3 }
        ).addTo(map)
    }

    const initMarker = () => {
        var b = [];
        items.map(item => {
            if(item.mappa_visibile[0] == "1" && item.latitudine[0])  {
                b.push([item.latitudine[0], item.longitudine[0]]);
                var marker = new L.marker([item.latitudine[0], item.longitudine[0]]);
                marker.bindPopup(item.localita[0]).openPopup();
                marker.addTo(map);
                marker.on('click', (e) => {
                    /*
                    L.DomEvent.stopPropagation(e)
                    console.log("click:", e);
                    this.$router.push({name: 'ProposteDetail', params: {id: item.$.id}});
                    */
                });

            }
        })
        var bounds = new L.LatLngBounds(b);
        map.fitBounds(bounds);
        console.log("bounds:", bounds);
    }

    useEffect(() => {
        initMap();
        initMarker();
    }, [])

    return (

        <div id="map" className="w-full"></div>

    )

}


export default Mappa;