import React from 'react'
import {codCategorie, listaTipoContratto} from '../utils/liste'

const Cerca = ({searchFilterCategory, searchFilterContractType}) => {



    const categorie = codCategorie.map((item, index) => (<option value={item.value} key={index}>{ item.text }</option>));

    const tipoContratto = listaTipoContratto.map((item, index) => (<option value={item.value} key={index}>{ item.text}</option>))

    const label = (item) => (<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-first-name">{ item }</label>)
    
    return (

        <div className="w-full bg-gray grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">



            <div>
                { label('Categoria') }
                <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        id="grid-state"
                        onChange={searchFilterCategory}>
                    { categorie }
                </select>
            </div>

            <div>
                { label('Tipo Contratto') }
                <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        id="grid-state"
                        onChange={searchFilterContractType}>
                    { tipoContratto }
                </select>
            </div>

            <div className="relative pt-6">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded absolute left-0">
                    CERCA
                </button>
            </div>

        </div>
    )
}

export default Cerca;