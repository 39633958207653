import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useHistory } from "react-router"

import Title from '../components/Title'

const Grazie = () => {

    let history = useHistory();
    const api_url = process.env.REACT_APP_API_URL;

   

    useEffect(() => {
        
    }, []);

    return (

        <div className="container mx-auto mb-32 px-4 md:px-0 text-center pt-24">
            <Title item="Richiesta Inviata con Successo!" />
            <p className="text-base greyText text-justify ubuntu">
               Ti risponderemo al più presto.
            </p>
        </div>


    )

}

export default Grazie;