import React, { useState, useEffect } from "react";
import axios from "axios";
import Title from "../components/Title";

const Terms = () => {
  return (
    <div className="container mx-auto text-black pt-12 pb-32 text-left">
      <div className="w-full text-4xl">Termini e condizioni d'uso</div>
      <div className="w-full text-base">
        <h1 dir="ltr">
          <span>Termini e condizioni d&rsquo;uso Oggetto</span>
        </h1>
        <p dir="ltr">
          <span>
            Le presenti condizioni generali d&rsquo;uso rappresentano
            l&rsquo;accesso e l&rsquo;uso del sito{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
            <span>,</span>
          </a>
          <span> accessibile mediante l&rsquo;url: </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it </span>
          </a>
          <span>
            (d&rsquo;ora in avanti &ldquo;titolare&rdquo;), sono atti
            vit&agrave; regolate dalle presenti condizioni d&rsquo;uso.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il sito &egrave; di propriet&agrave; di: STUDIO VIRGILIO di Valenza
            Jolena Ragione sociale: STUDIO VIRGILIO di Valenza Jolena Indirizzo:
            Via F.lli Cervi 46, Borgo Virgilio 46034 (MN) Partita IVA:
            02248030203
          </span>
        </p>
        <p dir="ltr">
          <span>Codice Fiscale: VLNJLN75D44E897C</span>
        </p>
        <p dir="ltr">
          <span>
            L&rsquo;accesso al sito e il suo utilizzo, presuppongono la lettura,
            la conoscenza e l&rsquo;accettazione di queste condizioni generali
            d&rsquo;uso.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Ogni richiesta di informazioni pu&ograve; essere inviata via email
            al seguente indirizzo{" "}
          </span>
          <a href="mailto:info@immobiliarestudiovirgilio.it">
            <span>info@immobiliarestudiovirgilio.it, </span>
          </a>
          <span>
            via telefono al seguente recapito telefonico 0376 1697786e via posta
            al seguente indirizzo Via F.lli Cervi 46, Borgo Virgilio 46034 (MN).
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Modifiche alle condizioni d&rsquo;uso</span>
        </h1>
        <p dir="ltr">
          <span>
            Il titolare potr&agrave; modificare o semplicemente aggiornare, in
            tutto o in parte, queste condizioni generali d&rsquo;uso. Le
            modifiche e gli aggiornamenti delle condizioni generali d&rsquo;uso
            saranno vincolanti non appena pubblicati sul sito web in questa
            stessa sezione. L&rsquo;accesso e l&rsquo;utilizzo del sito
            presuppongono l&rsquo;accettazione da parte dell&rsquo;utente delle
            presenti condizioni d&rsquo;uso.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Propriet&agrave; intellettuale</span>
        </h1>
        <p dir="ltr">
          <span>
            I contenuti presenti sul sito, (es: opere, immagini, fotografie,
            dialoghi, musiche, suoni, video, documenti, disegni, figure, loghi,
            men&ugrave;, pagine web, grafica, colori, schemi, strumenti, font,
            layout, metodi, processi, software ed ogni altro materiale in
            qualsiasi formato) sono protetti dal diritto d&rsquo;autore e da
            ogni altro diritto di propriet&agrave; intellettuale del titolare o
            di eventuali terzi dallo stesso contrattualizzati. &Egrave; vietata
            la riproduzione, in tutto o in parte, in qualsiasi forma, del sito e
            dei suoi contenuti, senza il consenso espresso in forma scritta del
            titolare.
          </span>
        </p>
        <p dir="ltr">
          <span>L&rsquo;utente &egrave; autorizzato a:</span>
        </p>
        <p dir="ltr">
          <span>
            visualizzare il sito ed i suoi contenuti fruendo dei relativi
            servizi ivi disponibili. L&rsquo;utente &egrave;, inoltre,
            autorizzato a compiere tutti quegli atti di riproduzione temporanei
            privi di rilevo economico proprio, che sono considerati transitori o
            accessori, parte integrante ed essenziale della stessa
            visualizzazione e fruizione del sito e dei suoi contenuti e tutte le
            altre operazioni di navigazione sul sito che siano eseguite solo per
            un uso legitti mo del medesimo.
          </span>
        </p>
        <p dir="ltr">
          <span>L&rsquo;utente non &egrave; autorizzato a:</span>
        </p>
        <p dir="ltr">
          <span>
            eseguire alcuna riproduzione, su qualsiasi supporto, in tutto o in
            parte del sito e dei suoi contenuti. Qualsiasi atto di riproduzione
            dovr&agrave; essere, di volta in volta, autorizzato dal titolare.
            Tali riproduzioni dovranno essere
          </span>
          <span>
            <br />
          </span>
        </p>
        <p dir="ltr">
          <span>
            comunque eseguite per scopi leciti e nel rispetto del diritto
            d&rsquo;autore e degli altri diritti di propriet&agrave;
            intellettuale.
          </span>
        </p>
        <p dir="ltr">&nbsp;</p>
        <h1 dir="ltr">
          <span>
            Utilizzo del sito e responsabilit&agrave; dell&rsquo;utente
          </span>
        </h1>
        <p dir="ltr">
          <span>
            L&rsquo;utente &egrave; personalmente responsabile per l&rsquo;uso
            del sito e dei relativi contenuti. Il titolare non potr&agrave;
            essere considerato responsabile dell&rsquo;uso non conforme alle
            norme di legge vigenti, del sito web e dei contenuti da parte di
            ciascuno dei propri utenti, salva la responsabilit&agrave; per dolo
            e colpa grave. In particolare, l&rsquo;utente sar&agrave;
            l&rsquo;unico ed il solo unico responsabile per la comunicazione di
            informazione e di dati non corretti , falsi o relativi a terzi
            soggetti , senza che questi abbiano espresso il loro consenso,
            nonch&eacute; in considerazione di un uso non corretto degli stessi.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Ogni materiale scaricato o altrimenti ottenuto attraverso
            l&rsquo;uso del servizio &egrave; a scelta e a rischio
            dell&rsquo;utente, pertanto ogni responsabilit&agrave; per eventuali
            danni a sistemi di computer o perdite di dati risultanti dalle
            operazioni di scarico ricade sull&rsquo;utente e non potr&agrave;
            essere imputata al titolare.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il titolare declina ogni responsabilit&agrave; per eventuali danni
            derivanti dall&rsquo;inaccessibilit&agrave; ai servizi presenti sul
            sito o da eventuali danni causati da virus, file danneggiati,
            errori, omissioni, interruzioni del servizio, cancellazione dei
            contenuti, problemi connessi alle reti, ai provider o a collegamenti
            telefonici e/o telematici, ad accessi non autorizzati, ad
            alterazioni di dati, al mancato e/o difettoso funzionamento delle
            apparecchiature elettroniche dell&rsquo;utente stesso.
          </span>
        </p>
        <p dir="ltr">
          <span>
            L&rsquo;utente &egrave; responsabile della custodia e del corretto
            utilizzo delle proprie informazioni personali, ivi comprese le
            credenziali che consentono di accedere a servizi riservati,
            nonch&eacute; di ogni conseguenza dannosa o pregiudizio che dovesse
            derivare a carico di{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it </span>
          </a>
          <span>
            ovvero di terzi a seguito del non corretto utilizzo, dello
            smarrimento, sottrazione di tali informazioni.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il titolare ha provveduto ad adottare ogni ragionevole accorgimento
            per evitare che siano pubblicati sul sito contenuti ed immagini che
            possano essere ritenuti lesivi del decoro, dei diritti umani e della
            dignit&agrave; delle persone, in tutte le possibili forme ed
            espressioni. In ogni caso, qualora i suddetti contenuti siano
            ritenuti lesivi della sensibilit&agrave; religiosa o etica o del
            decoro, l&rsquo;utente &egrave; pregato di comunicare tale
            condizione al titolare, il quale tuttavia avverte che ogni eventuale
            accesso ai contenuti considerati lesivi o oﬀensivi avviene da parte
            dell&rsquo;utente a proprio insindacabile giudizio ed a sua
            esclusiva e personale responsabilit&agrave;.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il titolare ha inoltre adottato ogni utile precauzione aﬃnch&eacute;
            tutte le informazioni presenti sul sito siano corrette, complete ed
            aggiornate, tuttavia lo stesso non assume nei confronti degli utenti
            alcuna responsabilit&agrave; circa l&rsquo;accuratezza e la
            completezza dei contenuti pubblicati sul sito, salvo quanto
            diversamente previsto dalla legge. Qualora un utente riscontrasse
            errori o mancati aggiornamenti delle informazioni presenti sul sito
            &egrave; pregato di comunicarlo al titolare utilizzando la casella
            email:{" "}
          </span>
          <a href="mailto:info@immobiliarestudiovirgilio.it">
            <span>info@immobiliarestudiovirgilio.it</span>
          </a>
        </p>
        <p dir="ltr">&nbsp;</p>
        <h1 dir="ltr">
          <span>Esclusione di responsabilit&agrave;</span>
        </h1>
        <p dir="ltr">
          <span>
            Il titolare declina ogni responsabilit&agrave;, inclusa la presenza
            di errori, la correzione degli errori, la responsabilit&agrave; del
            server ospitante il sito; non &egrave; altres&igrave; responsabile
            dell&rsquo;uso delle informazioni contenute, della loro correttezza
            e aﬃdabilit&agrave;. In nessun caso, inclusa la negligenza, il
            titolare sar&agrave; responsabile di ogni diretto o indiretto danno
            che possa risultare dall&rsquo;uso, o dalla incapacit&agrave; di
            usare, i materiali presenti nel sito.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il titolare non potr&agrave; essere ritenuto responsabile dei danni
            conseguenti alla mancata prestazione del servizio a causa
            dell&rsquo;errato o mancato funzionamento dei mezzi elettronici di
            comunicazione per cause estranee alla sfera del proprio prevedibile
            controllo (es: malfunzionamento dei server ed altri dispositivi
            elettronici anche non facenti parte integrante della rete internet,
            malfunzionamento dei software installati, virus informatici,
          </span>
          <span>
            <br />
          </span>
        </p>
        <p dir="ltr">
          <span>
            azioni di hacker o altri utenti aventi accesso alla rete).
            L&rsquo;utente si impegna dunque a tenere indenne e manlevare il
            titolare da qualsiasi responsabilit&agrave; e/o richiesta al
            riguardo.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Collegamenti esterni</span>
        </h1>
        <p dir="ltr">
          <span>
            Il sito pu&ograve; contenere collegamenti ad altri siti web che non
            hanno nessun collegamento con lo stesso. Il titolare non controlla
            ne monitora tali siti web e non ne garantisce pertanto in alcun modo
            i contenuti ne la gestione dei dati. L&rsquo;utente dovr&agrave;
            pertanto leggere attentamente le condizioni d&rsquo;uso dei siti
            terzi visitati e le relative privacy policy, in quanto le presenti
            condizioni d&rsquo;uso e la privacy policy si riferiscono unicamente
            al presente sito.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il presente sito pu&ograve; essere raggiunto anche attraverso siti
            terzi dove sar&agrave; presente un link o banner per accedere al
            sito. L&rsquo;atti vazione di link su siti terzi verso il presente
            sito, &egrave; possibile sempre e quando non sia lesivo delle
            presenti condizioni d&rsquo;uso. L&rsquo;atti vazione di link non
            autorizzati legitti mer&agrave; il titolare ad agire per
            l&rsquo;immediata disatti vazione dei link illegitti mi e per
            l&rsquo;eventuale riconoscimento della relativa pratica commerciale
            o concorrenza sleale ovvero medesimo gruppo.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Trattamento dei dati</span>
        </h1>
        <p dir="ltr">
          <span>
            I dati dell&rsquo;utente sono trattati conformemente a quanto
            previsto dalla normativa in materia di protezione dei dati
            personali, come specificato nell&rsquo;apposita sezione contenente
            l&rsquo;informativa ai sensi dell&rsquo;art.13 Regolamento UE
            2016/679 (privacy policy). Per pi&ugrave; informazioni accedere alla
            privacy policy del sito al link: http
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/privacy">
            <span>s://w</span>
          </a>
          <span>ww</span>
          <a href="http://www.immobiliarestudiovirgilio.it/privacy">
            <span>.immobiliar</span>
          </a>
          <span>e</span>
          <a href="http://www.immobiliarestudiovirgilio.it/privacy">
            <span>studiovirgilio.it/privacy</span>
          </a>
          <span>‐policy</span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Risoluzione delle controversie</span>
        </h1>
        <p dir="ltr">
          <span>
            La Commissione Europea ha istituito una piattaforma online per la
            risoluzione alternativa delle controversie. Tale strumento
            pu&ograve; essere utilizzato dall&rsquo;utente per risolvere in via
            non giudiziale ogni controversia relativa a e/o derivante da
            contratti di vendita di beni e fornitura di servizi stipulati in
            rete. Di conseguenza, l&rsquo;utente pu&ograve; usare tale
            piattaforma per la risoluzione di ogni disputa nascente dal
            contratto stipulato online. La piattaforma &egrave; disponibile al
            seguente indirizzo{" "}
          </span>
          <span>https://ec.europa.eu/consumers/odr/</span>
          <span>
            . In caso contrario il foro competente &egrave; quello di Mantova.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Le presenti condizioni sono state redatte in data 09/09/2022
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
      </div>
    </div>
  );
};

export default Terms;
