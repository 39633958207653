import React from 'react'


const NewsCard = ({item}) => {

    const api_url = process.env.REACT_APP_API_URL;

    return (

        <div className="rounded shadow-lg">
            <img className="w-full" src={api_url + '/' + item.thumb} alt="Sunset in the mountains" />
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 greyText">{ item.title }</div>
                <p className="text-gray-700 greyText text-base">
                    { item.intro.slice(0, 140) }
                </p>
            </div>
            <div className="px-6 py-4">
                <a href={`/news/${item._id}`} className="redBg hover:bg-blue-700 text-white font-bold py-2 px-4 py-2 px-4">Vedi</a>
            </div>
        </div>

    )

}

export default NewsCard;