import React from 'react'
import Social from './Social'

const footer = () => {

    return (

        <div className="relative bottom-0 left-0 redBg pt-10 pb-20">
           <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3">

                <div className="sm:px-1 md:px-5 lg:px-0 xl:px-0">
                        <p className="text-white">
                            <strong>STUDIO VIRGILIO di Valenza Jolena</strong>
                            <br/>
                            P.I.02248030203 - C.F. VLNJLN75D44E897C
                        </p>
                        <p className="text-white mt-5">
                            Via F.lli Cervi, 46 - 46034 Borgo Virgilio (MN) <br/>
                            Tel. 0376 1697786 <br/>
                        </p>
                        <p className="text-white mt-5">
                            E-mail: <a href="mailto:info@immobiliarestudiovirgilio.it">info@immobiliarestudiovirgilio.it</a> <br/>
                            Pec: <a href="mailto:studiovirgilio@arubapec.it">studiovirgilio@arubapec.it</a>
                        </p>
                </div>
                <div className="">
                    <p className="text-white">
                        <strong>Jolena Valenza</strong> <br/>
                        Tel. 0376 1697786 <br/>
                        <a href="mailto:j.valenza@immobiliarestudiovirgilio.it">j.valenza@immobiliarestudiovirgilio.it</a>
                    </p>
                    <p className="text-white mt-5">
                        <strong>Geom. Sebastiano Bosi </strong> <br/>
                        Tel. 340 1819887 <br/>
                        <a href="mailto:s.bosi@immobiliarestudiovirgilio.it">s.bosi@immobiliarestudiovirgilio.it</a>
                    </p>
                </div>
                <div className="invisible md:visible">
                    <Social/>
                </div>

           </div>

           <div className="container mx-auto mt-4">
               <p className="text-white text-xs">
                   Designed by <a href="https://abacus85.it " target="_blank">abacus85.it</a>
                   <span className="pl-10">
                       <a href="/privacy">Privacy Policy - GDPR & Cookies</a>
                   </span>
                   <span className="pl-10">
                       <a href="/termini-e-condizioni">Termini e condizioni d'uso</a>
                   </span>
                </p>
           </div>
        </div>

    )
}



export default footer;