import React from 'react'

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const PropertySlider = ( {items, title} ) => {

  console.log(items);

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      {items.map((item, index) => (
      <SwiperSlide key={index}>
        <div className='w-full bg-gray-100 gallery-height'>
          <img 
            src={item} 
            alt={title} 
            className="w-full h-full object-cover" 
        />
        </div>
      </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PropertySlider;

