import React from 'react'
import { useHistory } from "react-router"

const Categorie = ( {title, src, cat, type} ) => {
    const history = useHistory();

    const clickHandlers = () => {
        history.push(`/properties/${cat}/${type}`)
    }
    return (

        <div className="relative p-0">

            <img src={src} 
                alt={title}
                className="w-full z-10" />
                
            <div className="absolute top-0 w-full bg-black bg-opacity-50 hover:bg-opacity-25 z-50 h-full cursor-pointer"
                onClick={clickHandlers}>
                <p className="text-white absolute inset-0 flex items-center justify-center text-3xl">
                    { title }
                </p>
            </div>

        </div>

    )

}


export default Categorie;