import React from 'react'

const Social = () => {

    const items = [
        {
            name: 'facebook',
            icon: 'fab fa-facebook-square',
            link: 'https://www.facebook.com/Immobiliarestudiovirgilio'
        },
        {
            name: 'instagram',
            icon: 'fab fa-instagram',
            link: ''
        },
        {
            name: 'linkedin',
            icon: 'fab fa-linkedin',
            link: ''
        },
        {
            name: 'email',
            icon: 'fas fa-envelope',
            link: 'mailto:j.valenza@immobiliarestudiovirgilio.it'
        }
    ]
    return (

        <div className="flex col-span-12 items-start py-0 float-right">
            {items.map((item, index) => (
                <div className="inline-block text-center px-4 py-0 m-0 text-white text-xl link:text-white text-white" key={index}>
                    <a href={item.link} target="_blank" className="">
                        <i className={item.icon}></i>
                    </a>
                </div>
            ))}
        </div>

    )

}

export default Social;