import React from 'react'

const Title = ( {item} ) => {

    return (

        <p className="text-3xl greyText ubuntu border-b-1 border-b-gray-200 border-b-2 mb-2 text-left">{ item }</p>

    )

}

export default Title;