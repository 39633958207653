import React, {useState, useEffect} from 'react'
import axios from 'axios'
import getListPic from '../utils/getListPic'
import PropertySlider from '../components/PropertySlider'
import Title from '../components/Title'
import MappaProperty from '../components/MappaProperty'
import Table from '../components/Table'
import Form from '../components/FormContatti'
import ReactPlayer from 'react-player'

const Property = (props) => {

    const api_url = process.env.REACT_APP_API_URL;
    const id = props.match.params.id;

    const [property, setProperty] = useState({
        item: {},
        isLoading: false
    })

    const [sliders, setSliders] = useState({
        items: [],
        isLoading: false
    })

    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    })

    const getDetail = () => {
        axios.get(api_url + `/api/xml/${id}`)
            .then(response => {
                console.log(response.data);
                setProperty(
                    {
                        item:response.data[0],
                        isLoading: true
                    }
                )
                setSliders(
                    {
                        items: getListPic(response.data[0]),
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    let propertySlider = ''
    if (sliders.isLoading) {
        propertySlider = <PropertySlider
                            items={sliders.items}
                            title={property.item.tipologia} />    
    }

    let mappa = ""
    if (property.isLoading) {
        mappa = <MappaProperty 
                        className="mt-10 mb-10"
                        latitude={ property.item.latitudine[0] }
                        longitude={ property.item.longitudine[0] }/>
    }

    let video = ""
    if (property.item.video1) {
        video = (
        <div className='player-wrapper'>
            <ReactPlayer className='react-player' 
                        url={property.item.video1[0]}
                        width='100%'
                        height='100%' />
        </div>
        )
    }
    useEffect(() => {
        getDetail();
    }, [])

    return (

        <div className="container mx-auto pb-10 px-4 md:px-0">
            {property.isLoading ? (
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                        <div className="col-span-6">
                            { propertySlider }
                            { mappa }
                            <Title item="Richiedi Informazioni" className="mt-4" />
                            <Form subject={ 'Cod.:' + property.item.$.id + ' - ' + property.item.tipologia[0] } />
                        </div>
                        <div className="col-span-6">
                            <Title item={ property.item.titolo[0] } />
                            <p className="text-left text-sm">
                                {property.item.localita[0]} - {property.item.ubicazione[0]} <br/>
                                {property.item.indirizzo[0]} - {property.item.comune[0]} {property.item.regione[0]}
                            </p>
                            <p className="text-left text-3xl redText py-4">
                           
                                € {formatter.format(property.item.prezzo[0])}
                            </p>
                            <p className="text-left pt-5">
                                {property.item.testo[0]}
                            </p>
                            {video}
                            <Table 
                                item={property.item} />
                        </div>
                    </div>
            ) : ('')}
        </div>

    )

}

export default Property;