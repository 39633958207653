export const codCategorie = [
    {
        value: 'R',
        text: 'Residenziale'
    },
    {
        value: 'C',
        text: 'commerciale',
    },
    {
        value: 'T',
        text: 'Rustici e Terreni '
    },
    {
        value: 'U',
        text: 'Uffici, fondi'
    }
]

export const listaTipoContratto = [
    {
        id: '1',
        value: 'V',
        text: 'Vendita'
    },
    {
        id: '2',
        value: 'A',
        text: 'Affitto'
    },
    {
        id: '3',
        value: 'S',
        text: 'Stagionale o Vacanza'
    }
]

export const listaTipologiaImmobile = [
    {
        value: '1',
        text: 'Qualsiasi Tipo'
    },
    {
        value: '2',
        text: 'Albergo'
    },
    {
        value: '3',
        text: 'Appartamento'
    },
    {
        value: '4',
        text: 'Attività commerciale'
    },
    {
        value: '5',
        text: 'Azienda agricola'
    },
    {
        value: '6',
        text: 'Baita'
    },
    {
        value: '7',
        text: 'Casa singola'
    },
    {
        value: '8',
        text: 'Capannone industriale'
    },
    {
        value: '9',
        text: 'Castello'
    },
    {
        value: '10',
        text: 'Villa'
    },
    {
        value: '11',
        text: 'Mansarda'
    },
    {
        value: '12',
        text: "Rustico casale"
    },
    {
        value: '13',
        text: "Ufficio"
    },
    {
        value: '14',
        text: "Negozio"
    },
    {
        value: '15',
        text: "Terreno"
    },
    {
        value: '16',
        text: "Garage"
    },
    {
        value: '17',
        text: "Stabile"
    },
    {
        value: '18',
        text: "Agriturismo"
    },
    {
        value: '19',
        text: "Locale commerciale"
    },
    {
        value: '20',
        text: "Laboratorio"
    },
    {
        value: '21',
        text: 'Magazzino'
    },
    {
        value: '22',
        text: 'Colonica'
    },
    {
        value: '23',
        text: "Palazzo"
    },
    {
        value: '24',
        text: "Terratetto"
    },
    {
        value: '25',
        text: "Hotel"

    },
    {
        value: '26',
        text: "Bar"
    },
    {
        value: '27',
        text: "Ristorante"
    },
    {
        value: '28',
        text: "Forno"
    },
    {
        value: '29',
        text: "Villino"
    },
    {
        value: '30',
        text: "Appartamento indipendente"
    },
    {
        value: '31',
        text: "Attico"
    },
    {
        value: '32',
        text: "Villa a schiera"
    },
    {
        value: '33',
        text: "Bifamiliare"
    },
    {
        value: '34',
        text: "Casa semi indipendente"
    },    
    {
        value: '35',
        text: "Multiproprietà"
    },
    {
        value: '36',
        text: "Residence"
    },
    {
        value: '38',
        text: "Trulli"
    },
    {
        value: '40',
        text: "Masseria"
    },
    {
        value: '41',
        text: "Pizzeria / Pub"
    },
    {
        value: '42',
        text: "Tenuta-Complesso"
    },    
    {
        value: '43',
        text: "Annesso agricolo"
    },    
    {
        value: '44',
        text: "Terreno edificabile"
    },
    {
        value: '45',
        text: "Terreno industriale"
    },
    {
        value: '46',
        text: "Terreno agricolo"    
    },
    {
        value: '47',
        text: "Stanza/camera"    
    },
    {
        value: '48',
        text: "Loft"
    },
    {
        value: '49',
        text: "Nuova costruzione"
    },
    {
        value: '50',
        text: "Posto barca"    
    },
    {
        value: '51',
        text: "Stabilimento balneare"    
    }
]



