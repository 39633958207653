const getTipologiaImmobile = (item) => {
    switch(item) {
        case 1:
            return "Qualsiasi Tipo"
        case 2:
            return "Albergo"
        case 3:
            return "Appartamento"
        case 4:
            return "Attività commerciale"
        case 5:
            return "Azienda agricola"
        case 6:
            return "Baita"
        case 7:
            return "Casa singola"
        case 8:
            return "Capannone industriale"
        case 9:
            return "Castello"
        case 10:
            return "Villa"
        case 11:
            return "Mansarda"
        case 12:
            return "Rustico casale"
        case 13:
            return "Ufficio"
        case 14:
            return "Negozio"
        case 15:
            return "Terreno"
        case 16:
            return "Garage"
        case 17:
            return "Stabile"
        case 18:
            return "Agriturismo"
        case 19:
            return "Locale commerciale"
        case 20:
            return "Laboratorio"
        case 21:
            return "Magazzino"
        case 22:
            return "Colonica"
        case 23:
            return "Palazzo"
        case 24:
            return "Terratetto"
        case 25:
            return "Hotel"
        case 26:
            return "Bar"
        case 27:
            return "Ristorante"
        case 28:
            return "Forno"
        case 29:
            return "Villino"
        case 30:
            return "Appartamento indipendente"
        case 31:
            return "Attico"
        case 32:
            return "Villa a schiera"
        case 33:
            return "Bifamiliare"
        case 34:
            return "Casa semi indipendente"
        case 35:
            return "Multiproprietà"
        case 36:
            return "Residence"
        case 37:
            return ""
        case 38:
            return "Trulli"
        case 39:
            return ""
        case 40:
            return "Masseria"
        case 41:
            return "Pizzeria / Pub"
        case 42:
            return "Tenuta-Complesso"
        case 43:
            return "Annesso agricolo"
        case 44:
            return "Terreno edificabile"
        case 45:
            return "Terreno industriale"
        case 46:
            return "Terreno agricolo"
        case 47:
            return "Stanza/camera"
        case 48:
            return "Loft"
        case 49:
            return "Nuova costruzione"
        case 50:
            return "Posto barca"
        case 51:
            return "Stabilimento balneare"
    }
}

export default getTipologiaImmobile;