import React from "react";
import { useHistory } from "react-router"

import bannerImage from "../img/valuta-immobile.jpg";

const ValuePropertyBanner = () => {
    const history = useHistory();

    const clickHandlers = () => {
        history.push(`/quotazione`)
    }

    return (
        <div className="value-property-banner relative w-full cursor-pointer">
            <img src={bannerImage} alt="banner" className="w-full h-full object-cover cursor-pointer" />
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 hover:bg-opacity-25 cursor-pointer"
                onClick={clickHandlers}>
                <div className="w-full mx-auto h-full flex items-center justify-center cursor-pointer">
                    <div className="text-center text-white cursor-pointer">
                    <div className="text-center text-xl md:text-5xl text-white cursor-pointer">
                    Valuta il tuo Immobile
                    </div>
                    <button className="mt-4 border border-white text-white text-2xl px-4 py-2 rounded hover:bg-black hover:text-white cursor-pointer">
                        VALUTA
                    </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default ValuePropertyBanner;