import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useHistory } from "react-router"

import Title from '../components/Title'
const NewsDetail = (props) => {

    const api_url = process.env.REACT_APP_API_URL;
    const id = props.match.params.id;

    const [news, setNews] = useState({
        item: {},
        isLoading: false
    })

    const getNews = () => {
        axios.get(api_url + `/api/posts/${id}`)
            .then(response => {
                console.log(response.data.post);
                setNews(
                    {
                        item: response.data.post,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        getNews();
    }, [])

    return (
        <div className="container mx-auto mb-40 px-4 md:px-0">
            {news.isLoading ? (
                <div className="grid grid-cols-1 sm:grid-colds-1 md:grid-cols-2 lg:grid-cols-2 gap-4">

                    <div>
                        <img src={api_url + '/' + news.item.image} 
                            alt={news.item.title} 
                            className="w-full" />
                    </div>
                    <div>
                        <Title item={news.item.title} />
                        <p className="greyText text-base text-left">
                            { news.item.intro }
                        </p>
                    </div>
                </div>
            ) : ('')}
        </div>
    )
}

export default NewsDetail;