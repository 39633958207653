import React, { useState, useEffect } from "react";
import axios from "axios";
import Title from "../components/Title";

const Cookies = () => {
  return (
    <div className="container mx-auto text-black pt-12 pb-32 text-left">
      <div className="w-full text-4xl">Privacy Policy</div>
      <div className="w-full text-base">
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>
            STUDIO VIRGILIO di Valenza Jolena riconosce l&rsquo;importanza della
            protezione delle informazioni personali e si impegna a elaborarle in
            modo responsabile e in conformit&agrave; con le normative
            applicabili per la tutela dei dati personali in tutti i paesi in cui
            STUDIO VIRGILIO di Valenza Jolena opera.
          </span>
        </p>
        <p dir="ltr">
          <span>
            In questa sezione sono contenute le informazioni relative alle
            modalit&agrave; di gestione del sito{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
            <span>,</span>
          </a>
          <span>
            {" "}
            di propriet&agrave; di STUDIO VIRGILIO di Valenza Jolena, in
            riferimento al trattamento dei dati forniti dagli utenti del sito
            stesso.
          </span>
        </p>
        <p dir="ltr">
          <span>
            La presente informativa ha valore anche ai fini dell&rsquo;articolo
            13 del Regolamento (UE) n. 2016/679, relativo alla protezione delle
            persone fisiche con riguardo al trattamento dei dati personali
            nonch&eacute; alla libera circolazione di tali dati, per i soggetti
            che interagiscono con il sito{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it.</span>
          </a>
        </p>
        <p dir="ltr">
          <span>L&rsquo;informativa &egrave; resa solo per </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
          </a>
          <span>
            e non anche per altri siti web eventualmente consultati
            dall&rsquo;utente tramite link in esso contenuti
          </span>
        </p>
        <p dir="ltr">
          <span>
            L&rsquo;informativa pu&ograve; subire modifiche a causa
            dell&rsquo;introduzione di nuove norme al riguardo, si invita
            pertanto l&rsquo;utente a controllare periodicamente la presente
            pagina.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Se l&rsquo;utente ha meno di quattordici anni, ai senti
            dell&rsquo;Art. 8 c.1 regolamento (UE) 2016/679 e dell&rsquo;Art. 2
            &ndash; quinquies del D.Lgs 169/2003, cos&igrave; come modificato
            dal D.Lgs 181/18, dovr&agrave; legitti mare il suo consenso
            attraverso l&rsquo;autorizzazione dei genitori o di chi ne fa le
            veci.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Titolare del trattamento</span>
        </p>
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>S</span>
          <span>
            TUDIO VIRGILIO di Valenza Jolena &egrave; il &ldquo;titolare del
            trattamento&rdquo; di tutte le informazioni personali raccolte e
            utilizzate per l&rsquo;esercizio delle sue atti vit&agrave; di
            business. Ha sede legale in Via F.lli Cervi 46 &ndash; 46034 Borgo
            Virgilio (MN), codice fiscale VLNJLN75D44E897C, partita iva
            02248030203. I recapiti uﬃciali a cui poter scrivere sono la&nbsp;
            sede&nbsp; legale&nbsp; dell&rsquo;impresa&nbsp; e&nbsp; le&nbsp;
            caselle&nbsp; di&nbsp; posta: &nbsp; PEC{" "}
          </span>
          <a href="mailto:studiovirgilio@arubapec.it">
            <span>studiovirgilio@arubapec.it </span>
          </a>
          <span>o &nbsp; la &nbsp; casella &nbsp; generale </span>
          <a href="mailto:info@immobiliarestudiovirgilio.it">
            <span>info@immobiliarestudiovirgilio.it.</span>
          </a>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Base giuridica del trattamento</span>
        </p>
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>Il trattamento dei dati personali da parte di </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
          </a>
          <span>
            si basa sul consenso &ndash; ai sensi dell&rsquo;art.6, par. 1,
            lett. A) del Regolamento UE 2016/679 &ndash; espresso
            dall&rsquo;utente mediante la navigazione su questo sito web e la
            sua consultazione, cos&igrave; accettando la presente informativa.
            Il consenso &egrave; facoltativo e pu&ograve; essere revocato in
            qualsiasi momento mediante richiesta inviata a mezzo email a{" "}
          </span>
          <a href="mailto:info@immobiliarestudiovirgilio.it">
            <span>info@immobiliarestudiovirgilio.it </span>
          </a>
          <span>
            precisando che, in tal caso, in assenza di consenso non potranno
            essere erogati alcuni servizi e la navigazione sul sito web potrebbe
            essere compromessa.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Sicurezza dei dati personali</span>
        </p>
        <p dir="ltr">
          <span>
            I dati che ci forniscono gli utenti sono protetti con la tecnologia
            SSL (Secure Socket Layer). SSL &egrave; il metodo standard di
            cifratura dei dati personali o che consente il loro trasferimento in
            modo sicuro su Internet.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Luogo del trattamento dei dati</span>
        </p>
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il trattamento dei dati generato dall&rsquo;utilizzo del sito{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
          </a>
          <span>
            avviene presso Via F.lli Cervi 46 &ndash; 46034 Borgo Virgilio (MN).
            In caso di necessit&agrave;, i dati connessi al servizio newsletter
            o form di
          </span>
          <span>
            <br />
          </span>
        </p>
        <p dir="ltr">
          <span>
            contatto, possono essere trattati dal responsabile del trattamento o
            soggetti da esso incaricati a tal fine presso la relativa sede.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Conservazione dei dati</span>
        </h1>
        <p dir="ltr">
          <span>
            Conformemente a quanto prescritto dall&rsquo;art.5.1 (c) del
            Regolamento, i sistemi informativi e i programmi informatici
            utilizzati da{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
          </a>
          <span>
            sono configurati in modo da ridurre al minimo l&rsquo;uso di dati
            personali e identificativi; tali dati saranno trattati sono nella
            misura necessaria per il conseguimento delle finalit&agrave;
            indicate nella presente Policy. I dati verranno conservati per il
            periodo di tempo strettamente necessario al conseguimento delle
            finalit&agrave; in concreto perseguite e, in ogni caso, il criterio
            utilizzato per determinare il periodo di conservazione &egrave;
            improntato al rispetto dei termini consentiti dalle leggi
            applicabili e dai principi di minimizzazione del trattamento e
            limitazione della conservazione. I dati utilizzati ai fini di
            sicurezza (blocco tentativi di danneggiamento del sito) sono
            conservati per il tempo strettamente necessario al raggiungimento
            del fine anteriormente indicato.
          </span>
        </p>
        <p dir="ltr">
          <span>I dati del portale web sono allocati su server OVH</span>
        </p>
        <p dir="ltr">
          <span>OVH S.r.l.</span>
        </p>
        <p dir="ltr">
          <span>
            OVH Srl (Socio Unico) Codice Fiscale e Partita IVA 06157670966 Sede
            Legale: Via Leopoldo Cicognara, 7 &ndash; 20129, Milano &ndash;
            Italia Soggetti alla politica di privacy, descritta al seguente
            indirizzo web http
          </span>
          <a href="http://www.ovh.it/protezione">
            <span>s://w</span>
          </a>
          <span>ww.</span>
          <a href="http://www.ovh.it/protezione">
            <span>ovh.it/protezione</span>
          </a>
          <span>‐dati‐personali/</span>
        </p>
        <p dir="ltr">
          <span>
            Tutti i dati, sono salvati su database che sfruttano la tecnologia
            mysql, e sono criptati e protetti da visione di terze parti. Solo i
            nostri tecnici autorizzati e i tecnici autorizzati dei nostri
            partner possono accedervi per interventi tecnici o procedure di
            backup concordate con i nostri clienti o specificate nelle
            condizioni di utilizzo dei prodotti e/o servizi.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Finalit&agrave; del trattamento dei dati</span>
        </p>
        <p dir="ltr">
          <span>
            I dati raccolti dal sito internet durante il suo funzionamento sono
            utilizzati esclusivamente per le finalit&agrave; indicate e
            conservati per il tempo strettamente necessario a svolgere le atti
            vit&agrave; precisate, e comunque non oltre i 2 anni.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Cookies</span>
        </p>
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>
            L&rsquo;utilizzo dei cookie avviene nel rispetto della relativa
            normativa europea (diretti va 2019/136/CE ha modificato la diretti
            va 2002/58/CE) e nazionale (Provvedimento Garante per la protezione
            dei dati personali dell&rsquo;8 maggio 2014 e successivi chiarimenti
            nonch&eacute; Linee Guida cookie e altri strumenti di tracciamento
            del 10 giugno 2021 n.231). Per avere informazioni complete riguardo
            ai cookies puoi cliccare sul pulsante a fondo pagina “Gestisci il
            consenso ai cookie”. In questa sezione potrai revocare o modificare
            il consenso che hai dato ai cookie.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Tipologia di trattamento dei dati</span>
        </p>
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il trattamento dei dati personali &egrave; necessario per il
            perseguimento del legitti mo interesse del titolare del trattamento
            per finalit&agrave; di fornire informazioni circa l&rsquo;atti
            vit&agrave; di{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
          </a>
          <span>
            {" "}
            ai sensi dell&rsquo;art.6, par. 1, lett. F) del Regolamento UE
            2016/679, nel rispetto di quanto previsto dal medesimo Regolamento.
          </span>
          <span>
            <br />
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il presente sito fa uso di Log Files nei quali vengono conservate
            informazione in maniera automatizzata durante le visite degli
            utenti. Le informazioni raccolte potrebbero essere le seguenti:
          </span>
        </p>
        <p dir="ltr">
          <span>
            indirizzo IP, tipo di browser e parametri del dispositivo usato per
            connettersi al sito, nome del ISP, data e ora di visita, pagina web
            di provenienza del visitatore e di uscita, numero di click.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Le suddette informazioni sono trattate in forma automatizzata e
            raccolte in forma esclusivamente aggregata al fine di verificare il
            corretto funzionamento del sito e per motivi di sicurezza. Ai fini
            di sicurezza (filtri antispam, firewall, rilevazione virus), i dati
            registrati automaticamente possono eventualmente comprendere anche
            dati personali come l&rsquo;indirizzo IP, che potrebbe essere
            utilizzato, conformemente alle leggi vigenti in materia, al fine di
            bloccare tentativi di danneggiamento al sito medesimo o recare danno
            ad altri utenti, o comunque atti vit&agrave; dannose o sostituenti
            reato. Tali dati non sono mai utilizzati per
            l&rsquo;identificazione, o la profilazione dell&rsquo;utente, ma
            solo a fini di tutela del sito e dei suoi utenti, lati informazioni
            saranno trattate in base ai legitti mi interessi del titolare.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Tipologia di dati acquisiti</span>
        </p>
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>Il sito </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it </span>
          </a>
          <span>
            raccoglie i dati degli utenti direttamente dal sito o da terze
            parti. I dati sono necessari per la navigazione del sito e/o per la
            fruizione di alcune specifiche sezioni (a titolo d&rsquo;esempio:
            l&rsquo;invio di comunicazioni e/o richieste attraverso i form di
            contatto). I dati raccolti attraverso i form di registrazione e /o
            contatto sono: Nome, Cognome, indirizzo, numero di telefono,
            indirizzo email, richiesta dell&rsquo;utente, in taluni casi CV o
            documenti che l&rsquo;utente allega al form di contatto.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Dati forniti dall&rsquo;utente</span>
        </p>
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>
            Qualora il sito consenta l&rsquo;inserimento di commenti, oppure in
            caso di specifici servizi richiesti dall&rsquo;utente, ivi compresi
            la possibilit&agrave; di inviare CV per un eventuale rapporto
            lavorativo, il sito rileva automaticamente e registra alcuni dati
            identificativi dell&rsquo;utente, compreso l&rsquo;indirizzo mail.
            Tali dati si intendono volontariamente forniti dall&rsquo;utente al
            momento della richiesta di erogazione del servizio. Inserendo un
            commento o altra informazione l&rsquo;utente accetta espressamente
            l&rsquo;informativa privacy. I dati ricevuti verranno utilizzati
            esclusivamente per l&rsquo;erogazione del servizio richiesto e per
            il solo tempo necessario per la fornitura del servizio. Le
            informazioni che gli utenti del sito riterranno di rendere pubbliche
            tramite i servizi e gli strumenti messi a diposizione degli stessi,
            sono fornite dall&rsquo;utente consapevolmente e volontariamente,
            esentando il presente sito da qualsiasi responsabilit&agrave; in
            merito ad eventuali violazioni delle leggi. Spetta all&rsquo;utente
            verificare di avere i permessi pe l&rsquo;immissione di dati
            personali di terzi o di contenuti tutelati dalle norme nazionali ed
            internazionali. L&rsquo;invio facoltativo, esplicito e volontario di
            posta elettronica agli indirizzi indicati su questo sito comporta la
            successiva acquisizione dell&rsquo;indirizzo dal mittente,
            necessario per rispondere alle richieste, nonch&eacute; degli
            eventuali altri dati personali inseriti nella missiva.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Newsletter</span>
        </h1>
        <p dir="ltr">
          <span>
            Con il consenso previo dell'utente, e tramite registrazione
            volontaria via email, questo viene inserito in una lista di contatti
            a cui verranno inviate periodicamente delle email di aggiornamento,
            anche di promozione e marketing, derivanti dal presente sito.
            All'utente viene inviata una email (all'indirizzo email indicato)
            con all'interno un link su cui cliccare per confermare che l'utente
            sia realmente il proprietario dell'indirizzo email.
          </span>
          <span>
            <br />
          </span>
        </p>
        <p dir="ltr">
          <span>
            Dati personali che potrebbero essere raccolti nella newsletter:
            Nome, cognome, citt&agrave;, ragione sociale, partita IVA, codice
            fiscale, indirizzo, numero di telefono, indirizzo email.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Moduli di contatto</span>
        </h1>
        <p dir="ltr">
          <span>
            L'utente pu&ograve; compilare il/i moduli di contatto/richiesta
            informazioni, inserendo i propri dati e acconsentendo al loro uso
            per rispondere alle richieste di natura indicata nella intestazione
            del modulo. Dati personali che potrebbero essere raccolti: Nome,
            cognome, indirizzo, ragione sociale, email, citt&agrave;, numero
            telefono, indirizzo email, cv, allegati dell'utente, richiesta
            dell'utente.
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Interazione social network</span>
        </h1>
        <p dir="ltr">
          <span>
            Il presente sito incorpora anche plugin e/o bottoni per i social
            network, al fine di consentire una facile condivisione dei contenuti
            sui vostri social network. Tali plugin sono programmati in modo da
            non impostare alcun cookie all'accesso della pagina, per
            salvaguardare la privacy degli utenti. Eventualmente i cookie
            vengono impostati, se cos&igrave; previsto dai social network, solo
            quando l'utente fa eﬀetti vo e volontario uso del plugin. Si tenga
            presente che se l'utente naviga avendo eﬀettuato il login nel social
            network allora ha gi&agrave; acconsentito all'uso dei cookie
            veicolati tramite questo sito al momento dell'iscrizione al social
            network. Questo tipo di servizi permette di eﬀettuare interazioni
            con i social network, o con altre piattaforme esterne, direttamente
            dalle pagine di{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it. </span>
          </a>
          <span>Le interazioni e le informazioni acquisite da </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it </span>
          </a>
          <span>
            sono in ogni caso soggette alle impostazioni privacy dell'Utente
            relative ad ogni social network. Nel caso in cui sia installato un
            servizio di interazione con i social network, &egrave; possibile
            che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso
            raccolga dati di traﬃco relativi alle pagine in cui &egrave;
            installato.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Pulsante e widget sociali di Linkedin (LinkedIn Corporation). Il
            pulsante e i widget sociali di LinkedIn sono servizi di interazione
            con il social network Linkedin, forniti da LinkedIn Corporation.
            Dati Personali raccolti: Cookie e Dati di utilizzo. Luogo del
            trattamento: Stati Uniti &ndash; Privacy Policy: http
          </span>
          <a href="http://www.linkedin.com/legal/privacy">
            <span>s://w</span>
          </a>
          <span>ww</span>
          <a href="http://www.linkedin.com/legal/privacy">
            <span>.link</span>
          </a>
          <span>e</span>
          <a href="http://www.linkedin.com/legal/privacy">
            <span>din.com/legal/privacy</span>
          </a>
          <span>‐policy</span>
        </p>
        <p dir="ltr">
          <span>
            Pulsante Mi Piace e widget sociali di Facebook (Facebook, Inc.) Il
            pulsante "Mi Piace" e i widget sociali di Facebook sono servizi di
            interazione con il social network Facebook, forniti da Facebook,
            Inc. Dati Personali raccolti: Cookie e Dati di utilizzo. Luogo del
            trattamento: Stati Uniti &ndash; Privacy Policy: http
          </span>
          <a href="http://www.facebook.com/privacy/explanation">
            <span>s://www</span>
          </a>
          <span>.f</span>
          <a href="http://www.facebook.com/privacy/explanation">
            <span>acebook.c</span>
          </a>
          <span>o</span>
          <a href="http://www.facebook.com/privacy/explanation">
            <span>m/privacy/explanation.</span>
          </a>
        </p>
        <p dir="ltr">
          <span>
            Pulsante e widget sociali di Instagram (Instagram, Inc.). Il
            pulsante e i widget sociali di Instagram sono servizi di interazione
            con il social network Instagram, fornito da Instagram, Inc. Dati
            Personali raccolti: Cookie e Dati di utilizzo Luogo del trattamento:
            USA &ndash; Privacy Policy:
            https://help.instagram.com/196883487377501
          </span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <h1 dir="ltr">
          <span>Diritti degli interessati</span>
        </h1>
        <p dir="ltr">
          <span>
            Viene garantito il rispetto dei diritti dell&rsquo;utente
            nell&rsquo;ambito della protezione dei dati personali secondo la
            normativa GDPR. L&rsquo;utente ha il diritto di chiedere al
            titolare:
          </span>
        </p>
        <p dir="ltr">
          <span>
            L'accesso: l'utente pu&ograve; chiedere conferma che sia o meno in
            essere un trattamento di dati che lo riguarda, oltre a maggiori
            chiarimenti circa le informazioni di cui alla presente informativa;
          </span>
        </p>
        <p dir="ltr">
          <span>
            La retti fica: pu&ograve; chiedere di retti ficare o integrare i
            dati che ha fornito, qualora inesatti o incompleti;
          </span>
          <span>
            <br />
          </span>
        </p>
        <p dir="ltr">
          <span>
            La cancellazione: pu&ograve; chiedere che i suoi dati vengano
            cancellati, qualora non siano pi&ugrave; necessari alle nostre
            finalit&agrave;, in caso di revoca del consenso o opposizione al
            trattamento, in caso di trattamento illecito, ovvero sussista un
            obbligo legale di cancellazione o si riferiscano a soggetti minori
            di anni quattordici;
          </span>
        </p>
        <p dir="ltr">
          <span>
            La limitazione: pu&ograve; chiedere che i suoi dati siano trattati
            solo ai fini della conservazione, con esclusioni di altri
            trattamenti, per il periodo necessario alla retti fica dei suoi
            dati, in caso di trattamento illecito per il quale si opponga alla
            cancellazione, qualora lei debba esercitare i suoi diritti in sede
            giudiziaria e i dati conservati dal Titolare le possono essere utili
            e, infine, in caso di opposizione al trattamento e sia in corso una
            verifica sulla prevalenza dei motivi legitti mi del Titolare
            rispetto ai suoi.
          </span>
        </p>
        <p dir="ltr">
          <span>
            L'opposizione: pu&ograve; opporsi in qualunque momento al
            trattamento dei suoi dati, salvo che vi siano motivi legitti mi del
            Titolare per procedere al trattamento che prevalgano sui suoi, per
            esempio per l'esercizio o la propria difesa in sede giudiziaria.
          </span>
        </p>
        <p dir="ltr">
          <span>
            La portabilit&agrave;: pu&ograve; chiedere di ricevere i suoi dati,
            o di farli trasmettere ad altro titolare da lei indicato, in un
            formato strutturato, di uso comune e leggibile da dispositivo
            automatico.
          </span>
        </p>
        <p dir="ltr">
          <span>
            La revoca: pu&ograve; revocare il suo consenso all'utilizzo di
            cookie (Cookie Policy) in qualsiasi momento, poich&eacute; in questo
            caso costituisca la base del trattamento. La revoca del consenso
            comunque non pregiudica la liceit&agrave; del trattamento basata sul
            consenso svolto prima della revoca stessa.
          </span>
        </p>
        <p dir="ltr">
          <span>
            In qualsiasi momento l&rsquo;utente pu&ograve; richiedere di
            esercitare i suddetti diritti a{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
          </a>
          <span> rivolgendosi all&rsquo;indirizzo email: </span>
          <a href="mailto:info@immobiliarestudiovirgilio.it">
            <span>info@immobiliarestudiovirgilio.it.</span>
          </a>
          <span>
            {" "}
            Inoltre, l&rsquo;utente ha diritto di proporre reclamo nei confronti
            dell&rsquo;Autorit&agrave; di controllo Italiana: &ldquo;Garante per
            la Protezione dei Dati Personali&rdquo; nel caso in cui ritenga che
            i suoi diritti siano stati violati da{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it</span>
          </a>
          <span>
            {" "}
            o nel caso in cui non ritenga soddisfacente la risposta di{" "}
          </span>
          <a href="http://www.immobiliarestudiovirgilio.it/">
            <span>www.immobiliarestudiovirgilio.it </span>
          </a>
          <span>alle sue richieste.</span>
        </p>
        <p>
          <span>
            <span>
              <br />
              <br />
            </span>
          </span>
        </p>
        <p dir="ltr">
          <span>Modifiche all&rsquo;informativa sulla privacy</span>
        </p>
        <p>
          <span>
            <span>&nbsp;</span>
          </span>
        </p>
        <p dir="ltr">
          <span>
            La nostra Informativa sulla privacy pu&ograve; cambiare di tanto in
            tanto e qualsiasi modifica sar&agrave; comunicata all&rsquo;utente
            tramite un&rsquo;email o un avviso sul nostro sito web.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Il documento &egrave; stato aggiornato in data 09/09/2022 per essere
            conforme alle disposizioni normative in materia, ed in particolare
            in conformit&agrave; al Regolamento (UE) 2016/679
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
      </div>
    </div>
  );
};

export default Cookies;
