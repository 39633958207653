import React, {useEffect} from 'react'

import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet"
import L from 'leaflet';

// eslint-disable-next-line  
delete L.Icon.Default.prototype._getIconUrl  
// eslint-disable-next-line  
L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
})

const MappaProperty = ({latitude, longitude}) => {

    let map = '';

    const initMap = () => {
        map = L.map('map', {
            maxBounds: L.latLngBounds(L.latLng(-200, -250), L.latLng(200, 250)),
            zoomControl: false,
        }).setView([latitude, longitude], 4);

        L.tileLayer(
            'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
            { maxZoom: 15, minZoom: 3 }
        ).addTo(map)
    }

    const initMarker = () => {

        var marker = new L.marker([latitude, longitude]);
        marker.addTo(map);

    }

    useEffect(() => {
        initMap();
        initMarker();
    }, [])

    return (
        <div id="map" className="w-full mt-10"></div>
    )
}

export default MappaProperty;