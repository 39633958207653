import React, {useState} from 'react'


const CardProperty = ({item, showProperty}) => {
    
    const data = JSON.parse(item)

    const styles = {
        height: '300px',
        backgroundImage: `url(${data.url1[0]})`, 
    }

    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    })

    const [showText, setShowText] = useState({
        hover: false
    })

    const handleShowText = () => {
        console.log(showText.hover);
        setShowText({hover: !showText.hover})
    }

    let text = '';
    if (showText.hover) {
        text = (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-1 redBg" onClick={(e) => showProperty(data.$.id)}>
                <div className="p-1 mx-auto">
                    <p className="p-0 text-xs">Mq.</p>
                    <p className="p-0 text-md">{ data.mq[0]}</p>
                </div>
                <div className="p-1 mx-auto">
                    <p className="p-0 text-xs">Camere</p>
                    <p className="p-0 text-md">{ data.camere[0]}</p>
                </div>
                <div className="p-1 mx-auto">
                    <p className="p-0 text-xs">Bagni</p>
                    <p className="p-0 text-md">{ data.bagni[0]}</p>
                </div>
                <div className="p-1 mx-auto">
                    <p className="p-0 text-xs">Vani</p>
                    <p className="p-0 text-md">{ data.vani[0]}</p>
                </div>
            </div>
        )
    }

    return (

        <div className="relative bg-white bg-cover bg-center cursor-pointer"
            style={styles}
            onClick={(e) => showProperty(data.$.id)}
            onMouseEnter={handleShowText}
            onMouseLeave={handleShowText}
        >
            <div className="bg-black bg-opacity-50 text-white font-bold absolute top-0 right-0 p-1">
                <p>
                    {formatter.format(data.prezzo[0])}
                </p>
            </div>
            <div className="bg-black bg-opacity-50 text-white absolute inset-x-0 bottom-0">
                <p>
                    {data.tipologia[0]}
                </p>
                <p className="text-xs">
                    {data.localita[0]} - {data.comune[0]}
                </p>
                {text}

            </div>
        </div>

    )
}

export default CardProperty;