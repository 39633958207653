import React from 'react'
import iconTrueFalse from '../utils/iconTrueFalse'

const ListProperty = ({item, showProperty}) => {
    
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    })

    const listItems = [
        {name: 'Codice', value: item.$.id},
        {name: 'MQ.', value: item.mq},
        {name: 'Piano', value: item.piano},
        {name: 'Camere', value: item.camere},
        {name: 'Bagni', value: item.bagni},
        {name: 'Vani', value: item.vani},
        {name: 'Giardino', value: iconTrueFalse(item['giardino'][0])},
        {name: 'Balcone', value: iconTrueFalse(item['balcone'][0])},
        {name: 'Arredato', value: iconTrueFalse(item['arredato'][0])},
        {name: 'Mansarda', value: iconTrueFalse(item['mansarda'][0])},
        {name: 'Ascensore.', value: iconTrueFalse(item['ascensore'][0])},
    ]

    const styles = {
        minHeight: 'auto',
        backgroundImage: `url(${item.url1})`, 
    }

    return (
        <div className="grid w-full grid-cols-1 md-grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-4 mt-5 mb-10 shadow-2xl">

            <div className="col-span-3 bg-cover bg-center" style={styles} onClick={(e) => showProperty(item.$.id)}></div>

            <div className="col-span-9 align-left">
                <div className="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12">
                    <div className="col-span-10">
                        <p className="text-left ubuntu greyText text-2xl uppercase pt-5">
                            {item.titolo}
                        </p>
                        <p className="text-left text-base greyText ubuntu uppercase">
                            {item.localita} {item.comune}
                        </p>
                    </div>
                    <div className="col-span-2">
                        <p className="text-right text-white text-1-xl redBg pr-2">
                            {formatter.format(item['prezzo'][0])}
                        </p>
                    </div>
                </div>
                <p className="text-left text-base ubuntu greyText">
                    {item['testo'][0].slice(0, 280)}...
                </p>
                <div className="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12">

                    <div className="flex col-span-11 items-start h-24 divide-x divide-gray-400 py-5 invisible md:visible">
                        {listItems.map((item, index) => (
                            <div className="inline-block text-gray-700 text-center px-2 py-2 m-0 " key={index}>
                                <p className="text-xs ubuntu">
                                    {item.name}
                                </p>
                                <p className="text-sm ubuntu">
                                    {item.value}
                                </p>
                            </div>
                        ))}  
                    </div>
                    <div className="relative col-span-1 text-center">
                            <button className="absolute bottom-0 right-0 p-2 text-white redBg" onClick={(e) => showProperty(item.$.id)}>
                                <i class="fas fa-search"></i>
                            </button>
                    </div>
                </div>
            </div>

        </div>
    )
}



export default ListProperty;