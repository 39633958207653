import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useHistory } from "react-router"

import Cerca from '../components/Cerca'

import ListProperty from '../components/ListProperty'
const Properties = (props) => {

    let history = useHistory()

    const api_url = process.env.REACT_APP_API_URL;

    const [properties, setProperties] = useState({
        items: {},
        isLoading: false
    });

    

    const getProperties = () => {
        axios.get(api_url + '/api/xml')
            .then(response => {
                console.log(response);
                setProperties(
                    {
                        items: response.data,
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getPropertiesProps = () => {
        axios.get(api_url + '/api/xml')
            .then(response => {
                console.log(response);
                setProperties(
                    {
                        items: response.data.filter(item => {
                            return item.cod_categoria[0] === props.match.params.cat && item.contratto[0] === props.match.params.type
                        }),
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
    }

    const showDetail = (id) => {
        history.push("/properties/" + id)
    }

    const handleFilterCategory = (event) => {
        let q = event.target.value;
        axios.get(api_url + '/api/xml')
            .then(response => {
                setProperties(
                    {
                        items: response.data.filter(item => {return item.cod_categoria[0] === q;} ),
                        isLoading: true
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })  
    }

    const handleFilterContractType = (event) => {
        let q = event.target.value;
        axios.get(api_url + '/api/xml')
        .then(response => {
            setProperties(
                {
                    items: response.data.filter(item => {return item.contratto[0] === q;} ),
                    isLoading: true
                }
            )
        })
        .catch(error => {
            console.log(error);
        })  
    }

    let lista = '';
    if (properties.isLoading) {
        lista =  (
            properties.items.map((item, index) => (
                <ListProperty
                    key={index}
                    item={item}
                    showProperty={showDetail} />
            ))
        )
    }

    useEffect(() => {
        if (props.match.params.cat && props.match.params.type) {
            getPropertiesProps();
        }
        else {
            getProperties();
        }
    }, [])

    return (

        <div className="container mx-auto pt-10 pb-10 px-4 md:px-0">
            <Cerca 
                searchFilterCategory={handleFilterCategory}
                searchFilterContractType={handleFilterContractType} />
            {lista}
        </div>

    )
}

export default Properties;