import React, { useState } from 'react'
import logo from '../img/logo_immobiliare.png'

const Header = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);

    const menu = [
        {name: 'Home', to: '/'},
        {name: 'Chi Siamo', to: '/chi-siamo'},
        {name: 'Le Nostre Proposte', to: '/properties'},
        {name: 'News', to: '/news'},
        {name: 'Contatti', to: '/contatti'},
    ]


    return (

        <nav className="container mx-auto flex items-center justify-between flex-wrap bg-white-100 p-6">
            <div className="flex items-center flex-shrink-0 text-white mr-6"> 
                <a href="/">
                    <img src={logo} alt="" className="logo" />
                </a>                   
            </div>
            <div className="block lg:hidden">
                <button className="flex items-center px-3 py-2 border rounded greyText border-black hover:text-black hover:border-white"
                        onClick={() => setNavbarOpen(!navbarOpen)}>
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                </button>
            </div>
            <div className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }>
                <div className="text-sm w-full lg:flex-grow">
                {menu.map((item, index) => (
                    <a href={item.to} className="block mt-4 pl-4 pr-4 lg:inline-block lg:mt-0 greyText hover:greyText sm:text-sm xl:text-lg mr-4 ubuntu" key={index}>
                        { item.name }
                    </a>
                ))}
                <a href="/quotazione" className='redBg text-white px-2 py-2 sm:text-sm xl:text-lg ubuntu rounded'>
                Valuta il tuo immobile
                </a>
                </div>
                <div>

                </div>
            </div>
        </nav>

    )
}

export default Header;