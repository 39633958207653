const getTipoContratto = (item) => {
    switch(item) {
        case "V":
            return "Vendita"
        case "A":
            return "Affitto"
        case "S":
            return "Stagionale o Vacanza"
        default:
            return ""
    }
}

export default getTipoContratto;