
import React from 'react'
const iconTrueFalse = (item) => {

    let icon = <i class="far fa-times-circle"></i>
    if (item === "1") {
        icon = <i class="far fa-check-circle"></i>
    }
    return icon
}

export default iconTrueFalse;